import React, { PureComponent } from "react";
import ToggleButton from "react-toggle-button";

export class ToggleUiUx extends PureComponent {
  render() {
    return (
      <ToggleButton
        value={this.props.value || false}
        inactiveLabel={"UX"}
        activeLabel={"UI"}
        activeLabelStyle={{
          fontSize: "14px",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600
        }}
        inactiveLabelStyle={{
          fontSize: "14px",
          color: "#ffffff",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 600
        }}
        colors={{
          active: {
            base: this.props.color || "#5753ef",
            hover: this.props.color || "#5753ef"
          },
          inactive: {
            base: "#000000",
            hover: "#000000"
          }
        }}
        trackStyle={{
          width: "56px",
          height: "28px",
          backgroundColor: this.props.color || "#5753ef",
          fontSize: "1.45em"
        }}
        thumbAnimateRange={[5, 33]}
        thumbStyle={{
          width: "22px",
          height: "22px",
          backgroundColor: "#ffffff",
          border: "none",
          boxShadow: "none"
        }}
        onToggle={value => {
          this.props.setValue(!value);
        }}
      />
    );
  }
}
