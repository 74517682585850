import React, { PureComponent } from "react";
import ReactMorph from "react-morph";
import { SmartHome } from "./SmartHome";
import { ToggleUiUx } from "./ToggleUiUx";
import { CSSTransition } from "react-transition-group";
// import smartHomeUi from "../public/home-smarthome-ui.png";
// import travelUi from "../public/home-travel-ui.png";
// import coffeeUi from "../public/home-coffee-ui.jpg";
// import smartHomeUx from "../public/home-smarthome-ux.png";
// import travelUx from "../public/home-travel-ux.png";
// import coffeeUx from "../public/home-coffee-ux.png";
import shadow from "../shadow-home.jpg";
import polygon1 from "../polygon1.svg";
import polygon2 from "../1.svg";
import VizSensor from "react-visibility-sensor";
import { TravelApp } from "./TravelApp";
import { Coffee } from "./Coffee";

var classNames = require("classnames");

export class First extends PureComponent {
  constructor(props) {
    super(props);
    this.detailsRef = React.createRef();
    this.state = {
      show: false
    };
  }
  showDetails = go => {
    document.body.style.overflow = "hidden";
    this.setState({ out: true });
    go(1);
    this.setState({ show: true });

    setTimeout(() => {
      this.setState({ showDetails: true });
      this.setState({ outDetails: true });
    }, 400);
  };

  closeDetails = go => {
    this.setState({ outDetails: false, ui: undefined });
    this.detailsRef.current.scrollTop = 0;
    go(0);
    document.body.style.overflow = "auto";
    setTimeout(() => {
      this.setState({ show: false });
      this.setState({ showDetails: false });
      this.setState({ out: false });
    }, 400);
  };

  switchColors = value => {
    this.setState({
      try: value
    });
  };

  setValueCover = value => {
    this.setState({ ui: value });
  };

  getContent = () => {
    switch (this.props.order) {
      case 1:
        return (
          <SmartHome
            toggle={this.props.value}
            showDetails={this.state.showDetails}
            outDetails={this.state.outDetails}
            setValueCover={this.setValueCover}
            valueCover={this.state.ui}
          />
        );
      case 2:
        return (
          <TravelApp
            toggle={this.props.value}
            showDetails={this.state.showDetails}
            outDetails={this.state.outDetails}
            setValueCover={this.setValueCover}
            valueCover={this.state.ui}
          />
        );
      case 3:
        return (
          <Coffee
            toggle={this.props.value}
            showDetails={this.state.showDetails}
            outDetails={this.state.outDetails}
            setValueCover={this.setValueCover}
            valueCover={this.state.ui}
            switchColors={this.switchColors}
          />
        );
      default:
        return null;
    }
  };

  render() {
    let content = {};
    switch (this.props.order) {
      case 1:
        content = {
          header: "Smart \nHome",
          text: `This smart system allows a user to keep track
          and control multiple home devices, separately
          or as a group, with just one click.`,
          imageUi: `../home-smarthome-ui.png`,
          imageUx: `../home-smarthome-ux.png`,
          decorations: <div className="circle"></div>
        };
        break;
      case 2:
        content = {
          header: "Travel \nApp",
          text: `One stop trip planning app which builds
          personalized trip plans based on AI. 
          Finalized plans can also be shared among users.`,
          imageUi: `../home-travel-ui.png`,
          imageUx: `../home-travel-ux.png`,
          decorations: (
            <div className="deco-travel">
              <img src={polygon1} alt="polygon" />
              <img
                style={{
                  width: "30%",
                  right: "-42%",
                  position: "absolute",
                  bottom: 0
                }}
                src={polygon2}
                alt="polygon"
              />
            </div>
          )
        };
        break;
      case 3:
        content = {
          header: `Coffee \nTime`,
          text: `Hello coffee lovers!
          This app is perfect for anyone who is looking to 
          save some time and get his fresh coffee
          with just one click!`,
          imageUi: `../home-coffee-ui.jpg`,
          imageUx: `../home-coffee-ux.png`
        };
        break;
      default:
        content = {};
    }
    const cardClass = classNames({
      "card-image": true,
      "project-pink-bg-ui": this.props.value && this.props.order === 1,
      "project-purple-bg-ui": this.props.value && this.props.order === 2,
      "project-yellow-bg-ui": this.props.value && this.props.order === 3
    });

    const detailsContentClass = classNames({
      "details-image": true,
      tryBg:
        this.state.try &&
        (this.state.ui === true || this.state.ui === undefined),
      "project-pink-bg-ui1":
        this.props.order === 1 &&
        (this.state.ui === false || this.state.ui === true
          ? this.state.ui
          : this.props.value),
      "project-purple-bg-ui":
        this.props.order === 2 &&
        (this.state.ui === false || this.state.ui === true
          ? this.state.ui
          : this.props.value),
      "project-yellow-bg-ui1":
        this.props.order === 3 &&
        (this.state.ui === false || this.state.ui === true
          ? this.state.ui
          : this.props.value)
    });
    const projectClass = classNames({
      project: true,
      "show-ui": this.props.value,
      reverse: this.props.reverse
    });
    const toggleClass = classNames({
      "toggle-project-wrapper": true,
      "toggle-project-wrapper-reverse": this.props.reverse
    });
    const projectImageClass = classNames({
      "project-image-wrapper": true,
      smart: this.props.order === 1,
      travel: this.props.order === 2,
      coffee: this.props.order === 3
    });

    return (
      <ReactMorph
        spring={{ stiffness: 170, damping: 26 }}
        key={this.props.value}
      >
        {({ from, go, hide, to }) => (
          <div className={projectClass}>
            <div className="project-peak">
              {this.state.show ? null : (
                <div
                  className={
                    this.props.value ? "project-peak-bg" : "project-peak-bg-ux"
                  }
                  {...hide()}
                  onClick={this.showDetails.bind(this, go)}
                >
                  <div
                    {...from("cover", { zIndex: 2 })}
                    className={cardClass}
                  ></div>
                </div>
              )}

              <div
                ref={this.detailsRef}
                className={this.state.show ? "details pointers-all" : "details"}
              >
                <div className={detailsContentClass} {...to("cover")} />
                {this.state.show ? (
                  <svg
                    className="close-details"
                    onClick={this.closeDetails.bind(this, go)}
                    width="45px"
                    height="45px"
                    viewBox="0 0 45 45"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Home.P"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="SmartHome/UX"
                        transform="translate(-1803.000000, -48.000000)"
                        fill="#FFFFFF"
                      >
                        <g
                          id="Close-2"
                          transform="translate(1803.000000, 48.000000)"
                        >
                          <path
                            d="M22.72,0.4 C10.5697355,0.4 0.72,10.2497355 0.72,22.4 C0.72,34.5502645 10.5697355,44.4 22.72,44.4 C34.8702645,44.4 44.72,34.5502645 44.72,22.4 C44.72,10.2497355 34.8702645,0.4 22.72,0.4 L22.72,0.4 Z M22.72,41.4 C12.2265898,41.4 3.72,32.8934102 3.72,22.4 C3.72,11.9065898 12.2265898,3.4 22.72,3.4 C33.2134102,3.4 41.72,11.9065898 41.72,22.4 C41.72,27.4391133 39.71822,32.2718377 36.1550288,35.8350288 C32.5918377,39.39822 27.7591133,41.4 22.72,41.4 Z"
                            id="Shape"
                          ></path>
                          <path
                            d="M30.76,14.32 C30.1743649,13.7350932 29.2256351,13.7350932 28.64,14.32 L22.95,20.24 L16.76,14.24 C16.3777758,13.8493097 15.8132988,13.6974453 15.2866404,13.8436137 C14.759982,13.9897822 14.3545147,14.4108444 14.2283172,14.9426417 C14.1021196,15.474439 14.2751694,16.0327856 14.68,16.4 L20.83,22.32 L14.68,28.24 C14.2751694,28.6072144 14.1021196,29.165561 14.2283172,29.6973583 C14.3545147,30.2291556 14.759982,30.6502178 15.2866404,30.7963863 C15.8132988,30.9425547 16.3777758,30.7906903 16.76,30.4 L22.95,24.4 L28.64,30.32 C29.0072144,30.7248306 29.565561,30.8978804 30.0973583,30.7716828 C30.6291556,30.6454853 31.0502178,30.240018 31.1963863,29.7133596 C31.3425547,29.1867012 31.1906903,28.6222242 30.8,28.24 L25.07,22.24 L30.8,16.24 C31.2517951,15.674222 31.2349669,14.8664705 30.76,14.32 L30.76,14.32 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                ) : null}
                {this.state.show ? this.getContent() : null}
              </div>
            </div>
            <div className="project-description">
              <CSSTransition
                in={this.state.out}
                timeout={300}
                classNames="h1-home-project"
              >
                <div className={projectImageClass}>
                  {content.decorations}
                  <img
                    className="project-image"
                    src={content.imageUi}
                    alt="dashboard"
                  ></img>
                  <img
                    className="project-image"
                    src={content.imageUx}
                    alt="dashboard"
                  ></img>
                </div>
              </CSSTransition>
              <CSSTransition
                in={this.state.out}
                timeout={300}
                classNames="h1-home-project"
              >
                <h1>{content.header}</h1>
              </CSSTransition>
              <CSSTransition
                in={this.state.out}
                timeout={300}
                classNames="p-home-project"
              >
                <VizSensor
                  onChange={isVisible => {
                    if (isVisible) {
                      this.props.visible(this.props.order);
                    }
                  }}
                >
                  <p>{content.text}</p>
                </VizSensor>
              </CSSTransition>
              <div className={"hover-line"}>
                <p
                  className="project-type hover hover-1"
                  onClick={this.showDetails.bind(this, go)}
                >
                  View project
                </p>
              </div>
              <CSSTransition
                in={this.state.out}
                timeout={300}
                classNames="h1-home-project"
              >
                <div className={toggleClass}>
                  <img className={"shadow-image"} src={shadow} alt="shadow" />
                  <ToggleUiUx
                    value={this.props.value}
                    setValue={this.props.setValue}
                  />
                </div>
              </CSSTransition>
            </div>
          </div>
        )}
      </ReactMorph>
    );
  }
}
