import React, { PureComponent } from "react";

export class Contact extends PureComponent {
  render() {
    return (
      <div className="about-wrapper">
        <div className="navbar">
          <a href="/" className="navbar-logo">
            <p className="logo-rt">RT</p>
            <p className="logo-ui">UI/UX</p>
          </a>
          <div className="navbar-links">
            <a href="/#about" onClick={() => this.props.setRoute("about")}>
              HI, I’M RAZ
            </a>
            <a href="/#contact" onClick={() => this.props.setRoute("contact")}>
              LET’S TALK
            </a>
          </div>
        </div>
        <div className="about-content">
          <div style={{ width: "70%", paddingRight: "10%" }}>
            <h1>LET'S TALK</h1>
            <p>
              I’m based in Tel-Aviv.
              <br />
              Available via email: raztalbi@gmail.com
              <br />
              Phone: +972-502555838 <br />
              or{" "}
              <a
                style={{ color: "#ff1828", textDecoration: "none" }}
                href="https://www.linkedin.com/in/raz-weinberger-talbi/"
              >
                LinkedIn
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }
}
