import React, { PureComponent } from "react";
import { CSSTransition } from "react-transition-group";
import { ToggleUiUx } from "./ToggleUiUx";

var classNames = require("classnames");

export class Coffee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.toggle,
      try: false
    };
  }

  setValue = value => {
    this.setState({ value: value });
    this.props.setValueCover(value);
  };

  switchColors = () => {
    this.setState({ try: !this.state.try });
    this.props.switchColors(!this.state.try);
  };

  render() {
    const detailsClass = classNames({
      "content-page": true,
      "show-ui-content": this.state.value,
      "show-ui-try-content": this.state.value && this.state.try
    });

    return (
      <div className={detailsClass}>
        <CSSTransition
          in={this.props.outDetails}
          timeout={400}
          classNames="contents-in"
        >
          <div
            className={
              this.props.showDetails
                ? "content-section-img project-content-header-img-visible"
                : "content-section-img project-content-header-img"
            }
          >
            <div
              style={{
                position: "absolute",
                bottom: "-36vh",
                maxWidth: "115vh",
                right: "-58vh",
                left: 0,
                zIndex: 1,
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <img
                src="../coffee-ux-header.png"
                alt="dashboard1"
                className="content-image-ux"
              ></img>
              <img
                src="../coffee-ui-header1.png"
                alt="dashboard1"
                className="content-image-ui try"
              ></img>
              <img
                src="../coffee-ui-header2.png"
                alt="dashboard1"
                className="content-image-ui"
              ></img>
            </div>
            {this.state.try ? (
              <img
                className="looper2 looper3"
                src="../looper4.svg"
                alt="looper"
              ></img>
            ) : (
              <img
                className="looper2 looper3"
                src="../looper3.svg"
                alt="looper"
              ></img>
            )}
          </div>
        </CSSTransition>
        <CSSTransition
          in={this.props.outDetails}
          timeout={400}
          classNames="contents-in"
        >
          <div
            className={
              this.props.showDetails
                ? "toggle-wrapper-details-visible coffeeswitch"
                : "toggle-wrapper-details coffeeswitch"
            }
          >
            <ToggleUiUx
              value={this.state.value}
              setValue={this.setValue}
              color="#ff0080"
            />
          </div>
        </CSSTransition>

        <div className="content-section-img">
          <img
            src="../details-coffee-ux-1.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>

          <img
            src="../details-coffee-ui-12.jpg"
            alt="dashboard1"
            className="content-image-ui try"
          ></img>

          <img
            src="../details-coffee-ui-11.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
          {this.state.value && this.state.try ? (
            <img
              src="../tryme-2.png"
              alt="try"
              className="trymebitch"
              onClick={this.switchColors}
            ></img>
          ) : this.state.value && !this.state.try ? (
            <img
              src="../tryme.png"
              alt="try"
              className="trymebitch"
              onClick={this.switchColors}
            ></img>
          ) : null}
        </div>
        <div className="content-section-img">
          <img
            src="../details-coffee-ux-2.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>

          <img
            src="../details-coffee-ui-22.jpg"
            alt="dashboard1"
            className="content-image-ui try"
          ></img>

          <img
            src="../details-coffee-ui-21.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-coffee-ux-3.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>

          <img
            src="../details-coffee-ui-32.jpg"
            alt="dashboard1"
            className="content-image-ui try"
          ></img>

          <img
            src="../details-coffee-ui-31.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-coffee-ux-4.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>

          <img
            src="../details-coffee-ui-42.jpg"
            alt="dashboard1"
            className="content-image-ui try"
          ></img>

          <img
            src="../details-coffee-ui-41.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-coffee-ux-5.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>

          <img
            src="../details-coffee-ui-52.jpg"
            alt="dashboard1"
            className="content-image-ui try"
          ></img>

          <img
            src="../details-coffee-ui-51.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
      </div>
    );
  }
}
