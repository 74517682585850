import React, { PureComponent } from "react";
import { CSSTransition } from "react-transition-group";
import { ToggleUiUx } from "./ToggleUiUx";

var classNames = require("classnames");

export class SmartHome extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.toggle
    };
  }

  setValue = value => {
    this.setState({ value: value });
    this.props.setValueCover(value);
  };

  render() {
    const detailsClass = classNames({
      "content-page": true,
      "show-ui-content": this.state.value
    });

    return (
      <div className={detailsClass}>
        <CSSTransition
          in={this.props.outDetails}
          timeout={400}
          classNames="contents-in"
        >
          <div
            className={
              this.props.showDetails
                ? "content-section-img project-content-header-img-visible"
                : "content-section-img project-content-header-img"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }}
            >
              <img
                style={{ width: "240px" }}
                src="../smarthomelogo.svg"
                alt="SmartHome"
              ></img>
              <p
                style={{
                  textAlign: "left",
                  color: "#ffffff",
                  marginTop: "15vh",
                  fontSize: "2.33em",
                  lineHeight: 1.2,
                  fontWeight: "lighter"
                }}
              >
                Control your home
              </p>
              <p
                style={{
                  textAlign: "left",
                  color: "#ffffff",
                  fontSize: "2.33em",
                  fontWeight: "bold"
                }}
              >
                Connect your life
              </p>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "-36vh",
                maxWidth: "73vw",
                right: "-6vw"
              }}
            >
              <img
                src="../smarthome-ux-header.png"
                alt="dashboard1"
                className="content-image-ux"
              ></img>
              <img
                src="../smarthome-ui-header.png"
                alt="dashboard1"
                className="content-image-ui"
              ></img>
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={this.props.outDetails}
          timeout={400}
          classNames="contents-in"
        >
          <div
            className={
              this.props.showDetails
                ? "toggle-wrapper-details-visible"
                : "toggle-wrapper-details"
            }
          >
            <ToggleUiUx value={this.state.value} setValue={this.setValue} />
          </div>
        </CSSTransition>

        <div className="content-section-img">
          <img
            src="../details-smarthome-ux-1.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>
          <img
            src="../details-smarthome-ui-1.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-smarthome-ux-2.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>
          <img
            src="../details-smarthome-ui-2.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-smarthome-ux-2a.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>
          <img
            src="../details-smarthome-ui-2a.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-smarthome-ux-3.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>
          <img
            src="../details-smarthome-ui-3.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-smarthome-ux-4.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>
          <img
            src="../details-smarthome-ui-4.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-smarthome-ux-5.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>
          <img
            src="../details-smarthome-ui-5.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
        <div className="content-section-img">
          <img
            src="../details-smarthome-ux-6.jpg"
            alt="dashboard1"
            className="content-image-ux"
          ></img>
          <img
            src="../details-smarthome-ui-6.jpg"
            alt="dashboard1"
            className="content-image-ui"
          ></img>
        </div>
      </div>
    );
  }
}
