import React, { PureComponent } from "react";

export class About extends PureComponent {
  render() {
    return (
      <div className="about-wrapper">
        <div className="navbar">
          <a href="/" className="navbar-logo">
            <p className="logo-rt">RT</p>
            <p className="logo-ui">UI/UX</p>
          </a>
          <div className="navbar-links">
            <a href="/#about" onClick={() => this.props.setRoute("about")}>
              HI, I’M RAZ
            </a>
            <a href="/#contact" onClick={() => this.props.setRoute("contact")}>
              LET’S TALK
            </a>
          </div>
        </div>
        <div className="about-content">
          <div style={{ width: "70%", paddingRight: "10%" }}>
            <h1>Hi, I’m Raz Talbi</h1>
            <p>
              A proud cat & dog mom (their names are Luciano, Juicee and
              Oliver), a world exploring lover (visited 29 countries, till now;)
              and a multilingual being (I speak 3 languages, and counting).
              Also, I’m a UX designer (check out what I do).
            </p>
            <p style={{ marginTop: "20px" }}>
              Pleasure to meet you{" "}
              <span role="img" aria-label="smile">
                {" "}
                😊
              </span>
            </p>
            <h1>What I Bring to The Table</h1>
            <ul>
              <li>
                1 years of experience creating, designing and seeing through
                various digital products.
              </li>
              <li>
                Specialty in design system development. Including everything
                from initial research and planning to implementation and
                governance.
              </li>
              <li>A true passion for creation (not limited to UX). </li>
              <li>
                A thirst for knowledge. I’m always reading and taking online
                classes to grow my skills. It is important for me to grow as a
                professional and constantly improve.
              </li>
            </ul>
            <h1>My Dream Job Would Be…</h1>
            <ul>
              <li>
                Working for a data driven company that understands the value of
                UX and prioritizes it.
              </li>
              <li>
                Somewhere I can grow, learn from others and have others benefit
                from my experience, too.
              </li>

              <li>
                A friendly, social work environment is definitely important for
                me.
              </li>
            </ul>
          </div>
          <div style={{ width: "30%" }}>
            <img src="../chickens.jpg" alt="raz" />
          </div>
        </div>
      </div>
    );
  }
}
