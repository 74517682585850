import React, { PureComponent } from "react";
import { ToggleUiUx } from "./ToggleUiUx";
import { First } from "./First";
import { About } from "./About";
import { Contact } from "./Contact";
import arrowScroll from "../line.svg";
import arrowScrollUx from "../line-ux.svg";
import Dots from "react-carousel-dots";

export class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.firstProject = React.createRef();
    this.secondProject = React.createRef();
    this.thirdProject = React.createRef();

    this.state = {
      value: true,
      visibleIndex: 0
    };
  }

  componentDidMount() {}

  scrollTo = () => {
    this.firstProject.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };
  visible = order => {
    this.setState({ visibleIndex: order });
  };
  setValue = value => {
    this.setState({ value: value });
  };

  setRoute = route => {
    window.location.hash = route;
    this.setState({ route: route });
  };
  render() {
    console.log(this.state.route);
    console.log(window.location.hash);
    if (this.state.route === "about" || window.location.hash === "#about") {
      return <About setRoute={this.setRoute} />;
    }
    if (this.state.route === "contact" || window.location.hash === "#contact") {
      return <Contact setRoute={this.setRoute} />;
    }
    return (
      <div>
        <div className="main">
          <div className="header">
            <div className="navbar">
              <a
                href="/"
                className="navbar-logo"
                onClick={() => this.setState({ route: "/" })}
              >
                <p className="logo-rt">RT</p>
                <p className={this.state.value ? "logo-ui" : "logo-ux"}>
                  UI/UX
                </p>
              </a>
              <div className="navbar-links">
                <a
                  href="/#about"
                  onClick={() => this.setState({ route: "about" })}
                >
                  HI, I’M RAZ
                </a>
                <a
                  href="/#contact"
                  onClick={() => this.setState({ route: "contact" })}
                >
                  LET’S TALK
                </a>
              </div>
            </div>
            <Dots
              length={4}
              size={"0.9em"}
              active={this.state.visibleIndex}
              className="dots-wrapper"
            />
            <div className="portfolio-header">
              <h1>Hello there,</h1>
              <p>I am Raz Talbi,</p>
              <p style={{ display: "flex", alignSelf: "center" }}>
                <span style={{ marginRight: "5px" }}>A</span>
                <div className="toggle-project-wrapper-header">
                  <ToggleUiUx
                    value={this.state.value}
                    setValue={this.setValue}
                  />
                </div>
                <span className="space-ui">Designer, who</span>
              </p>
              <p>loves life changing ideas &</p>
              <p>Fluffy animals.</p>
            </div>
            <div className="view-portfolio">
              <p style={{ color: "#2d2d2d", fontSize: 12 }}>View portfolio</p>
              <img
                src={this.state.value ? arrowScroll : arrowScrollUx}
                alt="view portfolio"
                onClick={this.scrollTo}
              />
            </div>
          </div>
          <div className="ff" ref={this.firstProject}>
            <First
              value={this.state.value}
              setValue={this.setValue}
              order={1}
              visible={this.visible}
            />
          </div>

          <div className="ss" ref={this.secondProject}>
            <First
              value={this.state.value}
              setValue={this.setValue}
              order={2}
              reverse={true}
              visible={this.visible}
            />
          </div>

          <div className="tt" ref={this.thirdProject}>
            <First
              value={this.state.value}
              setValue={this.setValue}
              order={3}
              visible={this.visible}
            />
          </div>
          <img
            src={"../smarthome-ux-header.png"}
            className="pixel"
            alt="nn"
          ></img>
          <img
            src={"../smarthome-ui-header.png"}
            className="pixel"
            alt="nn"
          ></img>
          <img src={"../ui-login.png"} className="pixel" alt="nn"></img>
          <img src={"../ux-login.png"} className="pixel" alt="nn"></img>
          <img src={"../coffee-ux-header.png"} className="pixel" alt="nn"></img>
          <img
            src={"../coffee-ui-header1.png"}
            className="pixel"
            alt="nn"
          ></img>
          <img
            src={"../coffee-ui-header2.png"}
            className="pixel"
            alt="nn"
          ></img>
          <img
            src={"../details-smarthome-ui-1.jpg"}
            className="pixel"
            alt="nn"
          ></img>
          <img
            src={"../details-smarthome-ui-2.jpg"}
            className="pixel"
            alt="nn"
          ></img>
          <img
            src={"../details-travel-ui-1.jpg"}
            className="pixel"
            alt="nn"
          ></img>
          <img
            src={"./details-coffee-ui-11.jpg"}
            className="pixel"
            alt="nn"
          ></img>
          {/* <img
            src={"../details-travel-ui-2.jpg"}
            className="pixel"
            alt="nn"
          ></img> */}
          {/* <img
            src={"../details-coffee-ux-1.jpg"}
            className="pixel"
            alt="nn"
          ></img> */}
          {/* <img
            src={"./details-coffee-ui-11.jpg"}
            className="pixel"
            alt="nn"
          ></img>
          <img
            src={"./details-coffee-ui-12.jpg"}
            className="pixel"
            alt="nn"
          ></img> */}
        </div>
      </div>
    );
  }
}
